import React from "react";

const TermsAndConditions = ({ darkTheme }) => {
  return (
    <div
      id="terms-policy"
      className="modal fade"
      role="dialog"
      aria-hidden="true"
    >
      <div
        className="modal-dialog modal-lg modal-dialog-centered"
        role="document"
      >
        <div
          className={
            "modal-content " + (darkTheme ? "bg-dark-2 text-light" : "")
          }
        >
          <div className="modal-header">
            <h5 className={"modal-title " + (darkTheme ? "text-white" : "")}>
              Terms &amp; Policy
            </h5>
            <button
              type="button"
              className={"btn-close " + (darkTheme ? "btn-close-white" : "")}
              data-bs-dismiss="modal"
              aria-label="Close"
            />
          </div>
          <div className="modal-body p-4">
            <p>
              Welcome to our consulting website. By using our services, you are agreeing to these terms. Please read them carefully.
            </p>
            <h3 className={"mb-3 mt-4 " + (darkTheme ? "text-white" : "")}>
              Terms of Use
            </h3>
            <p>
              These are the terms of use for our consulting services. By using our services, you are agreeing to these terms.
            </p>
            <h5 className={"text-4 mt-4 " + (darkTheme ? "text-white" : "")}>
              Part I – Information we collect and control
            </h5>
            <p>
              We collect the following types of information: contact information, billing information, and service usage data.
            </p>
            <h5 className={"text-4 mt-4 " + (darkTheme ? "text-white" : "")}>
              Part II – Information that we process on your behalf
            </h5>
            <p>
              In the course of providing our services, we may process information on your behalf.
            </p>
            <h5 className={"text-4 mt-4 " + (darkTheme ? "text-white" : "")}>
              Part III – General
            </h5>
            <p>
              We reserve the right to modify these terms at any time. Changes will be posted on this page.
            </p>
            <h3 className={"mb-3 mt-4 " + (darkTheme ? "text-white" : "")}>
              Privacy Policy
            </h3>
            <p>
              We are committed to protecting your privacy. This policy explains how we handle your personal information.
            </p>
            <ol className="lh-lg">
              <li>
                We collect personal information directly from you.
              </li>
              <li>
                We use your personal information to provide and improve our services.
              </li>
              <li>
                We may share your personal information with third parties in accordance with our privacy policy.
              </li>
              <li>
                We take reasonable steps to protect your personal information.
              </li>
              <li>
                You can access and update your personal information.
              </li>
              <li>
                We retain your personal information as long as necessary to provide our services.
              </li>
              <li>
                We may update our privacy policy from time to time.
              </li>
            </ol>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TermsAndConditions;
