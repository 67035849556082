import React, { useState, useRef } from "react";
import Typewriter from "typewriter-effect";
import emailjs from "@emailjs/browser";
import "react-toastify/dist/ReactToastify.css";
import { useTranslation } from 'react-i18next'; 
import { ToastContainer, toast } from "react-toastify";
import videobg from "../videos/test.mp4";


const Home = ({ classicHeader, darkTheme, handleNavClick }) => {
  const formRef = useRef();
  const { t } = useTranslation();
  const [isModalOpen, setModalOpen] = useState(false);
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    whatsappNumber: "",
    registrationLocation: "",
    level: "",
    aiKnowledge: "",
    acknowledgment: false,
  });
  const [isFormValid, setFormValid] = useState(false);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    
    // Update formData based on input type
    const updatedData = {
      ...formData,
      [name]: type === "checkbox" ? checked : value,
    };
    
    setFormData(updatedData);

    // Validation: Check if all required fields are filled
    const allFilled = Object.entries(updatedData).every(([key, field]) => {
      if (key === "acknowledgment") {
        return field === true;
      }
      if (typeof field === "string") {
        return field.trim() !== "";
      }
      return false;
    });

    setFormValid(allFilled);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Send email using EmailJS
    emailjs
      .sendForm("service_sxjayzl", "template_e28v8tl", formRef.current, "X4tdMuRcGfdvtWL3h")
      .then(
        (result) => {
          // toast.success("Message sent successfully!", {
          //   position: "top-right",
          //   autoClose: 5000,
          //   hideProgressBar: false,
          //   closeOnClick: true,
          //   pauseOnHover: true,
          //   draggable: true,
          //   theme: darkTheme ? "dark" : "light",
          // });
          // Redirect to PayPal after sending the email
          window.open("https://www.paypal.com/ncp/payment/PRY6QD5WZQ9JS", "_blank");
          setModalOpen(false); // Close the modal
          formRef.current.reset(); // Reset the form
          setFormData({
            firstName: "",
            lastName: "",
            email: "",
            whatsappNumber: "",
            registrationLocation: "",
            level: "",
            aiKnowledge: "",
            acknowledgment: false,
          }); // Reset form data
          setFormValid(false); // Reset form validation
        },
        (error) => {
          toast.error("Something went wrong!", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            theme: darkTheme ? "dark" : "light",
          });
          console.log(error.text);
        }
      );
  };

  return (
    <section id="home">
      <div className="hero-wrap">
        <div className="hero-mask opacity-8 bg-dark" />
        <div className="player hero-bg parallax">
          <video
            src={videobg}
            autoPlay
            muted
            loop
            style={{ width: "100%", height: "100vh", objectFit: "cover" }}
          />
        </div>

        <div className="hero-content section d-flex min-vh-100">
          <div className="container my-auto">
            <div className="row">
              <div className="col-12 text-center">
                <p className="text-7 fw-500 text-white mb-2 mb-md-3">{t("Wellcome")}</p>
                <h2 className="text-16 fw-600 text-white mb-2 mb-md-3">
                  <Typewriter
                    options={{
                      strings: [
                        "We're B&M",
                        "Pioneers in Tech and E-commerce Solutions",
                        "Nous sommes B&M",
                        "Experts en Technologies et Solutions E-commerce",
                      ],
                      autoStart: true,
                      loop: true,
                    }}
                  />
                </h2>
                <p className="text-5 text-light mb-4">{t("based in Montreal, Canada.")}</p>
                <button
                  className="btn btn-outline-primary rounded-pill shadow-none smooth-scroll mt-2"
                  onClick={() => setModalOpen(true)}
                >
                  {t("Talk with us")}
                </button>
              </div>
            </div>
          </div>
          <a
            href="#about"
            className="scroll-down-arrow text-white smooth-scroll"
            onClick={(e) => {
              e.preventDefault();
              handleNavClick("about");
            }}
          >
            <span className="animated">
              <i className="fa fa-chevron-down" />
            </span>
          </a>
        </div>
      </div>

      {/* Bootstrap Modal */}
      <div
        className={`modal fade ${isModalOpen ? "show" : ""}`}
        style={{ display: isModalOpen ? "block" : "none" }}
        tabIndex="-1"
        role="dialog"
        aria-labelledby="modalLabel"
        aria-hidden={!isModalOpen}
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="modalLabel">
                Please fill out the form / Veuillez remplir le formulaire
              </h5>
              <button type="button" className="close" onClick={() => setModalOpen(false)}>
                <span>&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <form ref={formRef} onSubmit={handleSubmit}>
                <p>
                  <em>* Indicates required question / * Indique les questions obligatoires</em>
                </p>
                <div className="form-group">
                  <input
                    type="text"
                    name="firstName"
                    className="form-control"
                    placeholder="First Name / Prénom"
                    value={formData.firstName}
                    onChange={handleChange}
                    required
                  />
                </div>
                <div className="form-group">
                  <input
                    type="text"
                    name="lastName"
                    className="form-control"
                    placeholder="Last Name / Nom de famille"
                    value={formData.lastName}
                    onChange={handleChange}
                    required
                  />
                </div>
                <div className="form-group">
                  <input
                    type="email"
                    name="email"
                    className="form-control"
                    placeholder="Email Address / Adresse e-mail"
                    value={formData.email}
                    onChange={handleChange}
                    required
                  />
                </div>
                <div className="form-group">
                  <input
                    type="tel"
                    name="whatsappNumber"
                    className="form-control"
                    placeholder="WhatsApp Number / Numéro WhatsApp"
                    value={formData.whatsappNumber}
                    onChange={handleChange}
                    required
                  />
                </div>
                <div className="form-group">
                  <input
                    type="text"
                    name="registrationLocation"
                    className="form-control"
                    placeholder="Registration Location / Lieu d'inscription"
                    value={formData.registrationLocation}
                    onChange={handleChange}
                    required
                  />
                </div>
                <div className="form-group">
                  <label>Level / Niveau *</label>
                  <select
                    name="level"
                    className="form-control"
                    value={formData.level}
                    onChange={handleChange}
                    required
                  >
                    <option value="">Select Level / Sélectionnez le niveau</option>
                    <option value="Bac+">Bac+</option>
                    <option value="Bac">Bac</option>
                    <option value="Technician">Technician / Technicien</option>
                    <option value="Looking for training">Looking for training / En recherche de formation</option>
                  </select>
                </div>
                <div className="form-group">
                  <label>Assess your AI Knowledge / Évaluer votre connaissance en IA *</label>
                  <select
                    name="aiKnowledge"
                    className="form-control"
                    value={formData.aiKnowledge}
                    onChange={handleChange}
                    required
                  >
                    <option value="">Select Level / Sélectionnez le niveau</option>
                    <option value="Excellent">Excellent / Excellent</option>
                    <option value="Very Good">Very Good / Très bien</option>
                    <option value="Good">Good / Bien</option>
                    <option value="Normal">Normal / Normal</option>
                    <option value="Low">Low / Faible</option>
                    <option value="Poor">Poor / Mauvais</option>
                    <option value="Other">Other / Autre</option>
                  </select>
                </div>
                <div className="form-group form-check">
                  <input
                    type="checkbox"
                    name="acknowledgment"
                    className="form-check-input"
                    checked={formData.acknowledgment}
                    onChange={handleChange}
                    required
                  />
                 <label className="form-check-label">
  I acknowledge that payment is required now. / Je reconnais que le paiement est requis dès maintenant. *
</label>
                </div>
                <button type="submit" className="btn btn-primary" disabled={!isFormValid}>
                  Pay / Payer
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>

      <ToastContainer />
    </section>
  );
};

export default Home;
