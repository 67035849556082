import React from "react";
import { useTranslation } from 'react-i18next';
import Slider from "react-slick";

const Testimonials = ({ classicHeader, darkTheme }) => {
  const { t } = useTranslation();
  const reviews = [
    {
      name: "Roselyn from Montreal, Canada",
      position: "Business Owner",
      src: "images/testimonial/client-sm-3.jpg",
      desc: "I can't say enough good things about B&M's e-commerce solutions. They've been an absolute game-changer for my online business. From the moment I started working with them, I knew I was in capable hands. B&M not only created a stunning and user-friendly e-commerce site for me, but they also tailored it to my specific needs. The results have been incredible – my sales have soared, and I couldn't be happier with their services. If you're in the e-commerce game, B&M is the team to trust.",
      rating: 5,
    },
    {
      name: "Diego from Atlanta, USA",
      position: "Business Owner",
      src: "images/testimonial/client-sm-1.jpg",
      desc: "Working with B&M has been a breath of fresh air for my online retail venture. Their e-commerce solutions are top-notch. They've taken the hassle out of everything, from setting up a robust online store to integrating payment gateways seamlessly. What's really special about B&M is their dedication to my store's success. Thanks to their expertise, I've seen a remarkable uptick in sales and customer satisfaction. I wholeheartedly recommend B&M to anyone serious about excelling in e-commerce.",
      rating: 5,
    },
    {
      name: "Frank from New York, USA",
      position: "Business Owner",
      src: "images/testimonial/client-sm-2.jpg",
      desc: "My journey in the e-commerce world has been quite a ride, and B&M has been a fantastic companion. Their e-commerce solutions have made a world of difference. They didn't just create a beautiful online store; they made sure it was functional, efficient, and perfectly aligned with my business goals. The results speak for themselves – my e-commerce business is thriving, and it's all thanks to B&M. If you want to succeed in e-commerce, look no further.",
      rating: 5,
    },
    {
      name: "Julie from Quebec city, Canada",
      position: "Student",
      src: "images/testimonial/client-sm-4.jpg",
      desc: "Mon parcours dans le monde de l'e-commerce a été une aventure passionnante, et B&M a été un partenaire exceptionnel. Leurs solutions e-commerce ont fait une énorme différence. Ils n'ont pas simplement créé une superbe boutique en ligne, ils ont veillé à ce qu'elle soit fonctionnelle, efficace, et parfaitement alignée sur mes objectifs commerciaux.",
      rating: 5,
    },
  ];

  var settings = {
    dots: true,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 2,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
    ],
  };
  return (
    <section
      id="testimonial"
      className={"section " + (darkTheme ? "bg-dark-2" : "")}
    >
      <div className={"container " + (classicHeader ? "" : "px-lg-5")}>
        {/* Heading */}
        <div className="position-relative d-flex text-center mb-5">
          <h2
            className={
              "text-24  text-uppercase fw-600 w-100 mb-0 " +
              (darkTheme ? "text-muted opacity-1" : "text-light opacity-4")
            }
          >
            {t("Testimonial")}
            
          </h2>
          <p
            className={
              "text-9 text-dark fw-600 position-absolute w-100 align-self-center lh-base mb-0 " +
              (darkTheme ? "text-white" : "text-dark")
            }
          >
            {" "}
            {t("Client Speak")}
            
            <span className="heading-separator-line border-bottom border-3 border-primary d-block mx-auto" />
          </p>
        </div>
        {/* Heading end*/}

        <Slider {...settings}>
          {reviews.length > 0 &&
            reviews.map((value, index) => (
              <div className="item" key={index}>
                <div
                  className={
                    " rounded p-5 " + (darkTheme ? "bg-dark" : "bg-light")
                  }
                >
                  <div className="d-flex align-items-center mt-auto mb-4">
                    <img
                      className="img-fluid rounded-circle border d-inline-block w-auto"
                      src={value.src}
                      alt=""
                    />
                    <p className="ms-3 mb-0">
                      <strong
                        className={
                          "d-block fw-600 " +
                          (darkTheme ? "text-white" : "text-dark")
                        }
                      >
                        {value.name}
                      </strong>
                      <span className="text-muted fw-500">
                        {" "}
                        {value.position}{" "}
                      </span>
                    </p>
                  </div>
                  <p
                    className={
                      " fw-500 mb-4 " + (darkTheme ? "text-white" : "text-dark")
                    }
                  >
                    “{value.desc}”
                  </p>
                  <span className="text-2">
                    {[...Array(value.rating)].map((value, i) => (
                      <i className="fas fa-star text-warning" key={i} />
                    ))}
                  </span>
                </div>
              </div>
            ))}
        </Slider>
      </div>
    </section>
  );
};

export default Testimonials;
