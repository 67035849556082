import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

const resources = {
  en: {
    translation: {
      "Home": "Home",
      "About": "About",
      "What We Do": "What We Do",
      "Destinations": "Technologies",
      "Portfolio": "Portfolio",
      "Client": "Client",
      "Contact": "Contact",
      "About Us": "About Us",
      "WHERE":"Tools",
      "Success rate":"Customers Satisfaction rate",
      "Know Us More": "Know Us More",
      "We're B&M Consulting leaders in international mobility": " We're B&M, Pioneers in Tech and E-commerce Solutions",
      "What we Do?":"What we Do?",
      "At B&M, we are dedicated to helping individuals and families from around the world make a successful transition to life in a new country. Whether you're a student, professional, or entrepreneur, we provide personalized support and guidance to help you navigate the complex process of immigration.": "At B&M, we're at the forefront of technological innovation, specializing in cutting-edge solutions for businesses in the digital landscape. Whether you're an established enterprise, a growing startup, or an e-commerce entrepreneur, we provide unparalleled expertise and support to propel your tech and e-commerce endeavors to new heights.",
      "team":"Our team of experienced professionals offers a range of tech services.",
      "Years Experience": "Years Experience",
      "Happy Clients": "Happy Clients",
      "Countries Served": "Countries Served",
      "Awards Received": "Awards Received",
      "Download Brochure" :"Download Brochure",
      "office 1:" :"office 1:",
      "office 2:" :"office 2:",
      "Stuttgart, Germany":"Stuttgart, Germany",
      "based in Montreal, Canada.":"based in Montreal, Canada.",
      "University Admissions": "E-commerce",
      "Visa Applications": "Cloud solutions",
      "Bursary Obtention Success Rate": "Web designs",
      "Job Placement Success Rate": "Maintenance",
      "Wellcome":"welcome",
      "CANADA - title": "E-commerce Platforms",
      "CANADA - desc": "We successfully implement e-commerce platforms such as Shopify, WooCommerce, BigCommerce, Magento, and custom solutions. Discover how we leverage these platforms to create high-performing e-commerce solutions.",
      "GERMANY - title": "Content Management Systems",
      "GERMANY - desc": "Our expertise extends to popular Content Management Systems (CMS) like WordPress, Drupal, Joomla, and others. Learn how we harness these systems for efficient website construction and management.",
      "SPAIN - title": "Cloud Services",
      "SPAIN - desc": "We excel in implementing cloud services such as Amazon Web Services (AWS), Microsoft Azure, Google Cloud Platform (GCP), and more. Explore how we use these platforms to host and scale web applications.",
      "UNITED STATES (US) - title": "Web Development Technologies",
      "UNITED STATES (US) - desc": "We work with a variety of web development technologies and programming languages, including HTML, CSS, JavaScript, PHP, Python, and specific frameworks and libraries.",
      "EUROPEAN UNION (EU) - title": "Database Management ",
      "EUROPEAN UNION (EU) - desc": "Our expertise extends to managing and optimizing databases, including both SQL and NoSQL solutions. Learn how we ensure data integrity and security.",
      "FRANCE - title": "Payment Gateways and Security",
      "FRANCE - desc": "We integrate payment gateways and implement security technologies to safeguard sensitive customer data during online transactions. Discover our commitment to secure e-commerce transactions.",
      //... add the rest of your English translations here
      "Talk with us":"Register to our AI training",
      "Get in Touch":"Get in Touch",
      "SEND US A NOTE" :"SEND US A NOTE",
      "ADDRESS":"ADDRESS",
      "FOLLOW US":"FOLLOW US",
      "Send Message": "Send Message",
      "Testimonial": "Testimonial",
      "Client Speak":"Client Speak",
      "University Application Services": "E-commerce Development",
    "We provide guidance and support for clients who want to study abroad in Canada or Europe, including assistance with choosing universities and programs, completing application forms, writing personal statements, and tracking application status.":
      "We specialize in creating custom e-commerce solutions tailored to your business needs. From building user-friendly online stores to integrating secure payment gateways, we ensure your online shop runs smoothly.",
    "Visa Application Services": "Web Design",
    "We offer help for clients who need a visa to study or work abroad, including guidance on navigating the visa application process, filling out application forms, gathering necessary documents, and tracking application status.":
      "Our team of designers crafts visually appealing and responsive websites that leave a lasting impression. We focus on user experience, ensuring your site looks great on all devices.",
    "Writing Motivation Letters": "Website Deployment",
    "We provide writing services for clients who need to submit a motivation letter as part of their university or job applications, including drafting, editing, and tailoring the letter to the specific requirements of the application.":
      "We handle the entire deployment process, from setting up hosting to launching your website. We make sure your site is live and ready to attract visitors.",
      "Visa Status Change": "Domain Name Discovery",
      "We provide assistance for clients who need to change their visa status, such as transitioning from tourist to student visa or obtaining a work permit. We offer guidance on the required documentation, application process, and other necessary steps to successfully change the visa status.":
        "Finding the perfect domain name can be a challenge. We assist in identifying and securing the ideal domain that reflects your brand and resonates with your audience.",
      // ...
    "Pre-Departure Orientation": "Customization and Branding",
    "We provide guidance and advice on preparing for life abroad, including understanding cultural differences and customs, finding accommodation and essential services, and understanding university rules and regulations.":
      "We offer extensive customization options to make your e-commerce site unique. Our branding expertise helps your business stand out in a competitive online market.",
    "Career Counselling and Job Placement": "Maintenance and Support",
    "We offer assistance with exploring career options, finding employment opportunities, developing resumes and CVs, improving interview skills, and navigating the job search process for clients who want to study abroad with the goal of securing employment after graduation.":
      "Your online presence is an ongoing commitment. We provide post-launch maintenance and support to ensure your site remains up-to-date and performs at its best.",
    // ...
  
    }
  },
  fr: {
    translation: {
      "Home": "Accueil",
      "About": "À propos",
      "What We Do": "Ce que nous faisons",
      "Destinations": "Technologies",
      "Portfolio": "Portfolio",
      "Client": "Client",
      "Contact": "Contact",
      "About Us": "À propos de nous",
      "Know Us More": "En savoir plus sur nous",
      "What we Do?":" Que Faisons-Nous?",
      "WHERE":"OUTILS",
      "We're B&M Consulting leaders in international mobility": "On est B&M Consulting, leaders en solutions e-commerce et en tech",
      "team": "Notre équipe de professionnels expérimentés propose une vaste gamme de services technologiques",
       "Years Experience": "Années d'expérience",
       "10 Countries": "10 Pays",
       "Happy Clients": "Clients satisfaits",
       "Countries Served": "Pays desservis",
       "Awards Received": "Récompenses reçues",
       "Download Brochure" :"Télécharger la Brochure",
       "Success rate":"Taux de satisfaction",
       "office 1:" :"bureau 1:",
       "office 2:" :"bureau 2:",
       "Stuttgart, Germany":"Stuttgart, Allemagne",
       "based in Montreal, Canada.":"basé a Montreal, Canada.",
       "University Admissions": "E-commerce",
       "Visa Applications": "Solutions cloud",
       "Bursary Obtention Success Rate": "Design web",
       "Job Placement Success Rate": "Maintenance",
       "Wellcome":"Bienvenue",
       "Talk with us":"S'inscrire à notre formation en IA",
       "CANADA - title": "Plateformes de Commerce Électronique",
       "CANADA - desc": "Nous mettons en œuvre avec succès des plateformes de commerce électronique telles que Shopify, WooCommerce, BigCommerce, Magento, ainsi que des solutions personnalisées.",
       "GERMANY - title": "Systèmes de Gestion de Contenu",
       "GERMANY - desc": "Notre expertise s'étend aux systèmes de gestion de contenu (CMS) populaires tels que WordPress, Drupal, Joomla, et d'autres. Découvrez comment nous utilisons ces systèmes pour construire et gérer des sites web de manière efficace.",
       "SPAIN - title": "Services Cloud",
       "SPAIN - desc": "Nous excellons dans la mise en place de services cloud tels qu'Amazon Web Services (AWS), Microsoft Azure, Google Cloud Platform (GCP), et bien d'autres.",
       "UNITED STATES (US) - title": "Technologies de Développement Web",
       "UNITED STATES (US) - desc": "Nous travaillons avec une variété de technologies de développement web et de langages de programmation, notamment HTML, CSS, JavaScript, PHP, Python, ainsi que des frameworks et des bibliothèques spécifiques.",
       "EUROPEAN UNION (EU) - title": "Gestion de Bases de Données",
       "EUROPEAN UNION (EU) - desc": "Notre expertise s'étend à la gestion et à l'optimisation des bases de données, y compris des solutions SQL et NoSQL. Découvrez comment nous assurons l'intégrité et la sécurité des données.",
       "FRANCE - title": "Passerelles de Paiement et Sécurité",
       "FRANCE - desc": "Nous intégrons des passerelles de paiement et mettons en place des technologies de sécurité pour protéger les données sensibles des clients lors des transactions en ligne. ",
       "Get in Touch":"Nous contacter",
       "SEND US A NOTE" :"ENVOYEZ NOUS UN MESSAGE",
        "ADDRESS":"ADRESSE",
        "FOLLOW US":"NOUS SUIVRE",
        "Send Message": "Envoyer le message",
        "Testimonial":"Témoignages",
        "Client Speak":"Opinions des Clients",
       "University Application Services": "Développement E-commerce",
    "We provide guidance and support for clients who want to study abroad in Canada or Europe, including assistance with choosing universities and programs, completing application forms, writing personal statements, and tracking application status.":
      "Nous sommes spécialisés dans la création de solutions e-commerce sur mesure adaptées à vos besoins commerciaux. De la création de boutiques en ligne conviviales à l'intégration de passerelles de paiement sécurisées, nous veillons à ce que votre magasin en ligne fonctionne parfaitement.",
    "Visa Application Services": "Conception de Sites Web",
    "We offer help for clients who need a visa to study or work abroad, including guidance on navigating the visa application process, filling out application forms, gathering necessary documents, and tracking application status.":
      "Notre équipe de designers crée des sites web visuellement attrayants et adaptatifs qui laissent une impression durable. Nous mettons l'accent sur l'expérience utilisateur, en veillant à ce que votre site soit parfaitement accessible sur tous les appareils.",
    "Writing Motivation Letters": "Déploiement de Sites Web",
    "We provide writing services for clients who need to submit a motivation letter as part of their university or job applications, including drafting, editing, and tailoring the letter to the specific requirements of the application.":
      "Nous gérons l'ensemble du processus de déploiement, de la configuration de l'hébergement au lancement de votre site web. Nous nous assurons que votre site est en ligne et prêt à attirer des visiteurs.",
      "Visa Status Change": "Découverte de Noms de Domaine",
      "We provide assistance for clients who need to change their visa status, such as transitioning from tourist to student visa or obtaining a work permit. We offer guidance on the required documentation, application process, and other necessary steps to successfully change the visa status.":
        "Trouver le nom de domaine parfait peut être un défi. Nous vous aidons à identifier et à sécuriser le domaine idéal qui reflète votre marque et séduit votre public.",
      "Pre-Departure Orientation": "Personnalisation et Image de Marque",
      "We provide guidance and advice on preparing for life abroad, including understanding cultural differences and customs, finding accommodation and essential services, and understanding university rules and regulations.":
        "Nous offrons de nombreuses options de personnalisation pour rendre votre site e-commerce unique. Notre expertise en matière d'image de marque permet à votre entreprise de se démarquer sur un marché en ligne concurrentiel.",
      "Career Counselling and Job Placement": "Maintenance et Support",
      "We offer assistance with exploring career options, finding employment opportunities, developing resumes and CVs, improving interview skills, and navigating the job search process for clients who want to study abroad with the goal of securing employment after graduation.":
        "Votre présence en ligne est un engagement continu. Nous assurons la maintenance et le support après le lancement pour garantir que votre site reste à jour et performant.",
    }
  }
};

i18n
  .use(initReactI18next)
  .init({
    resources,
    lng: 'en',
    keySeparator: false,
    interpolation: {
      escapeValue: false
    }
  });

export default i18n;
